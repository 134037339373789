<script setup lang="ts">

interface MenuITem {
    label: string,
    icon: string,
    to: string,
}
const props = defineProps<{items: MenuITem[]}>()


</script>

<template>
  <div class="divide-y divide-primary-100/20">
    <template v-for="(opt, i) in props.items" :key="i">
      <div class="w-full">
        <PvButton
          unstyled
          class="py-3 px-4 text-white hover:bg-[rgb(255,255,255)]/20 w-full text-left"
          @click="navigateTo(opt.to)"
        >
          <div class="flex gap-4">
            <div class="text-2xl hidden md:flex items-center">
              <span v-if="opt.icon" :class="getIconClass(opt.icon)"></span>
            </div>
            <div class="text-md text-center md:text-left w-full">
              {{ opt.label }}
            </div>
          </div>
        </PvButton>
      </div>
    </template>
  </div>
</template>
